import { apiPost } from '@/features/shared/utils/dataFetching'
import {
  type Content,
  type CMSCatNavData,
  type CMSPageData,
  type StoresCarouselData,
  type CMSProductShelfData,
  type LayoutGroupData,
} from '@/features/cms/components/CMS/types'
import { type QueryFunctionContext } from '@tanstack/react-query'
import { isOnServer } from '@shared/constants/util'
import { type StoreParams } from '@/features/shared/utils/dataFetching/storeParams'
import {
  type getAddressParamsForCMS,
  createFetchCMSDataConfig,
  toCategory,
} from '@/features/cms/services/CMS/utils'
import { type ShoppingCategories } from '@/features/shop/services/ShoppingCategories/types'
import { getCMSWrapperProps } from '@/features/cms/components/CMS/utils/getCMSWrapperProps'
import { type ProgressiveRevealShelfQueryKey } from '@/features/cms/services/CMS/queries'

type CMSQueryKey<T> = Readonly<
  [
    `CMS_${string}`,
    {
      slug?: string
      storeParams: StoreParams | undefined
      addressParams: ReturnType<typeof getAddressParamsForCMS> | undefined
      subscriptionType: string | undefined
    } & T
  ]
>

export type CMSDataQueryFunctionContext<T extends object = object> =
  QueryFunctionContext<CMSQueryKey<T>>

export const fetchCMSData = (context: CMSDataQueryFunctionContext) => {
  return apiPost<CMSPageData>({
    config: {
      ...createFetchCMSDataConfig({
        context,
        isOnServer: isOnServer(),
        enableDeferredHydration: true,
      }),
      signal: context.signal,
    },
    context,
    fetcherName: 'fetchCMSData',
  })
}

export const fetchDeferredHydration = ({
  url,
  headers,
  body,
}: {
  url: string
  headers: Record<string, string>
  body: unknown
}) => {
  // url from hydration callback object does not include gateway path
  const fullUrl = `/cms${url}`
  return apiPost<Content>({
    fetcherName: 'fetchDeferredHydration',
    config: {
      url: fullUrl,
      headers,
      data: body,
    },
  })
}

export const fetchCMSCatNavData = async (
  context: CMSDataQueryFunctionContext
): Promise<ShoppingCategories> => {
  const response = await apiPost<CMSCatNavData>({
    config: createFetchCMSDataConfig({
      context,
      isOnServer: isOnServer(),
      contentAlias: 'web-category-nav',
      enableDeferredHydration: false,
    }),
    context,
    fetcherName: 'fetchCMSCatNavData',
  })
  const { id: spyglassID } = getCMSWrapperProps({
    content_type_id: response.content_type_id,
    id: response.id,
  })
  return {
    category: response.data.category_navs?.map(toCategory) ?? [],
    featured_categories: response.data.featured_navs?.map(toCategory) ?? [],
    spyglassID,
  }
}

export const fetchProgressiveRevealShelfData = async (
  context: QueryFunctionContext<ProgressiveRevealShelfQueryKey>
) => {
  const [, params] = context.queryKey
  const { zip, metro_id, store_id, store_location_id } = params.storeParams

  const response = await apiPost<CMSProductShelfData>({
    fetcherName: 'fetchProgressiveRevealShelfData',
    config: {
      url: 'cms/v1/content-alias/search-progressive-reveal',
      data: {
        zip,
        metro_id,
        store_id,
        store_location_id,
        user_id: params.userId,
        input_variables: { product_id: params.productId },
        platform: 'web',
      },
    },
  })
  return Array.isArray(response.data?.items)
    ? { ...response, shelfProductId: params.productId }
    : null
}

export const fetchOrderPlacedStoreCarousel = async (
  context: CMSDataQueryFunctionContext
): Promise<StoresCarouselData> => {
  const response = await apiPost<StoresCarouselData>({
    fetcherName: 'fetchOrderPlacedStoreCarousel',
    config: createFetchCMSDataConfig({
      context,
      isOnServer: false,
      contentAlias: 'order-placed-store-carousel',
      enableDeferredHydration: false,
    }),
    context,
  })
  return response
}

export const fetchOrderPlacedCMSShelves = (
  context: CMSDataQueryFunctionContext,
  excludeProductIds: number[]
) => {
  const config = createFetchCMSDataConfig({
    context,
    isOnServer: false,
    contentAlias: 'order-placed-page',
    enableDeferredHydration: false,
  })
  return apiPost<LayoutGroupData>({
    fetcherName: 'fetchOrderPlacedCMSShelves',
    config: {
      ...config,
      data: {
        ...config.data,
        input_variables: {
          exclude_product_ids: excludeProductIds,
        },
      },
    },
    context,
  })
}

export const fetchZeroResultsCMSShelves = (
  context: CMSDataQueryFunctionContext<{ query: string }>
) => {
  const config = createFetchCMSDataConfig({
    context,
    isOnServer: false,
    contentAlias: 'zero-results-page',
    enableDeferredHydration: false,
  })
  return apiPost<LayoutGroupData>({
    fetcherName: 'fetchZeroResultsCMSShelves',
    config: {
      ...config,
      data: {
        ...config.data,
        input_variables: {
          query: context.queryKey[1].query,
        },
      },
    },
    context,
  })
}
