import { useCallback, useMemo } from 'react'
import { useGuestUserContext } from '@/features/shared/context/GuestUserContext'
import { useQueryClient } from '@tanstack/react-query'
import { useUser } from '@/features/account/services/User/hooks'
import {
  catNavCmsQueryOptions,
  cmsQueryOptions,
} from '@/features/cms/services/CMS/queries'
import {
  getAddressParamsForCMS,
  getGuestAddressParamsForCMS,
} from '@/features/cms/services/CMS/utils'
import {
  type StoreParams,
  useStoreParams,
  getStoreParams,
} from '@/features/shared/utils/dataFetching/storeParams'
import { getUser } from '@/features/account/services/User/utils'
import { getQueryClient } from '@/features/shared/utils/dataFetching/reactQuery/SegwayQueryClient'

export const useCMSParamsWithoutSlug = () => {
  const user = useUser()
  const storeParams = useStoreParams()
  const { guestAddress } = useGuestUserContext() ?? {}

  return useMemo(() => {
    return {
      storeParams,
      addressParams: user
        ? getAddressParamsForCMS(user)
        : getGuestAddressParamsForCMS(guestAddress),
      subscriptionType: user?.subscription?.type ?? undefined,
    }
  }, [guestAddress, storeParams, user])
}

export const usePrefetchCmsShopQueries = () => {
  const queryClient = useQueryClient()

  const prefetchCMSRetailerQueries = useCallback(
    (
      params: Pick<StoreParams, 'store_id' | 'store_location_id' | 'metro_id'>
    ) => {
      const user = getUser(getQueryClient())
      if (!user) return

      const storeParams = getStoreParams(user)
      const addressParams = getAddressParamsForCMS(user)

      queryClient.prefetchQuery(
        cmsQueryOptions({
          slug: 'shop',
          storeParams: { ...storeParams, ...params },
          addressParams,
        })
      )

      queryClient.prefetchQuery(
        catNavCmsQueryOptions({
          storeParams: { ...storeParams, ...params },
          addressParams,
        })
      )
    },
    [queryClient]
  )

  return { prefetchCMSRetailerQueries }
}
